import React from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../../assets/icons/left-arrow.svg";

import WithAppLayout from "../../../components/WithAppLayout";
import WithQuery from "../../../components/WithQuery";
import { GET_CANDIDATE_USER_QUERY } from "../../../graphql/agencies";
import CandidateDetails from "../../../components/Shortlist/CandidateDetails";
import UpdateCandidateProfileForm from "../../../components/UpdateCandidateProfileForm";
import UploadCandidateResumeForm from "../../../components/UploadCandidateResumeForm";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import UpdateCandidateRolePrefForm from "../../../components/UpdateCandidateRolePrefForm";

function CandidateDetailsPage({ data, error, loading, refetch, currentUser }) {
  const candidateUser = data?.agencyCandidateUser;
  const navigate = useNavigate();

  if (error) {
    return null;
  }

  if (!candidateUser) {
    return null;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Flex h="100%" direction="column" flex="1">
      <Text
        onClick={() => {
          // if we can naviate to the previous page, do so
          // otherwise, navigate to the home page
          if (window.history.length > 1) {
            navigate(-1);
          } else {
            navigate("/");
          }
        }}
        fontSize="md"
        lineHeight="6"
        fontWeight="normal"
        cursor="pointer"
        mb={4}
      >
        <Icon fontSize="10px" as={LeftArrow} /> Back
      </Text>
      <Flex direction="row" align="flex-start" justify="flex-start">
        <Flex direction="column" flex="1">
          <CandidateDetails
            candidateReport={{ candidateUser }}
            refetch={refetch}
          />
        </Flex>
        <Flex
          direction="column"
          flex="1"
          align="flex-start"
          justify="flex-start"
          gap="16"
          ml={10}
        >
          <UpdateCandidateProfileForm
            candidateUser={candidateUser}
            refetch={refetch}
          />
          <UpdateCandidateRolePrefForm
            candidateUser={candidateUser}
            refetch={refetch}
          />
          <UploadCandidateResumeForm
            candidateUser={candidateUser}
            refetch={refetch}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

function PageComponent({ children }) {
  const { id } = useParams();

  return WithQuery(
    CandidateDetailsPage,
    GET_CANDIDATE_USER_QUERY,
    { id },
    {
      children,
    }
  )();
}

export default WithAppLayout(PageComponent, {
  showGradientBackground: true,
});
