import { gql } from "@apollo/client";

// Candidate triage status
export const CandidateTriageStatusApprovedByRecruiter = "ApprovedByRecruiter";
export const CandidateTriageStatusPassedByRecruiter = "PassedByRecruiter";
export const CandidateTriageStatusApprovedByClient = "ApprovedByClient";
export const CandidateTriageStatusPassedByClient = "PassedByClient";
export const CandidateTriageStatusHired = "Hired";

export const MoblyzeClosedReasonCreatedAccidentally = "CreatedAccidentally";
export const MoblyzeClosedReasonCancelledByClient = "CancelledByClient";
export const MoblyzeClosedReasonFilledByUs = "FilledByUs";
export const MoblyzeClosedReasonFilledByCompetitor = "FilledByCompetitor";
export const MoblyzeClosedReasonOther = "Other";
export const MoblyzeClosedReasonFilledByClient = "FilledByClient";

export const MoblyzeClosedReasons = [
  MoblyzeClosedReasonCreatedAccidentally,
  MoblyzeClosedReasonCancelledByClient,
  MoblyzeClosedReasonFilledByUs,
  MoblyzeClosedReasonFilledByCompetitor,
  MoblyzeClosedReasonOther,
  MoblyzeClosedReasonFilledByClient,
];

export const CANDIDATE_USER_FRAGMENT = gql`
  fragment CandidateUserFragment on User {
    id
    firstName
    lastName
    fullName
    email
    primaryPhone

    profileImageUrl
    referralCode
    referrals {
      id
      createdAt
    }

    candidateProfile {
      id
      roles {
        id
        name
      }
      workLocations {
        id
        name
      }
      activelyLooking
      linkedInURL
      resumeUrl
      resumeContent
      resumePreviewUrl
      certification{
        id
        name
        url
      }

      agencySlugId
      agencyLogoUrl
    }

    createdAt
    updatedAt
  }
`;

export const CANDIDATE_USER_LIST_FRAGMENT = gql`
  fragment CandidateUserListFragment on User {
    id
    firstName
    lastName
    fullName
    email
    primaryPhone

    candidateProfile {
      id
      resumeUrl
      resumeContent
      resumePreviewUrl

      agencySlugId
      agencyLogoUrl
    }

    createdAt
    updatedAt
  }
`;

export const CANDIDATE_TRIAGE_FRAGMENT = gql`
  ${CANDIDATE_USER_FRAGMENT}
  fragment CandidateTriageFragment on CandidateTriage {
    id
    moblyzeJobId
    candidateUserId
    status
    clientNotes
    candidateUser {
      ...CandidateUserFragment
    }
  }
`;

export const AGENCY_CANDIDATE_TRIAGE_REPORT_FRAGMENT = gql`
  ${CANDIDATE_USER_FRAGMENT}

  fragment AgencyCandidateTriageReportFragment on AgencyCandidateTriageReport {
    candidateUser {
      ...CandidateUserFragment
    }

    viewedByRecruiter
  }
`;

export const MOBLYZE_JOB_FRAGMENT = gql`
  fragment MoblyzeJobFragment on MoblyzeJob {
    id

    bullhornId

    status

    category
    title

    employmentType
    numOpenings

    clientOrganizationId
    employerName
    employerLogoUrl

    employerContactName
    employerContactEmail
    employerContactPhone
    agencyContactName
    agencyContactEmail
    agencyContactPhone

    location

    duration
    startDate

    requirementsDescription
    responsibilitiesDescription
    otherDescription

    coverImageUrl

    vesselId
    vessel {
      id
      name
      subtitle
      flag
      ageDescription
      wifiSpeedDescription
      isSmoking
      totalCrew
      vesselUrl
      imageUrl
    }

    closedReason
    closedNote
    reactivationNote

    hiredInstructions
    siteAddress

    roles {
      id
      name
    }

    workLocations {
      id
      name
    }

    payRateValue
    payFrequency
    payCurrency

    createdAt
    updatedAt
  }
`;

export const ACTIVE_JOBS_QUERY = gql`
  ${MOBLYZE_JOB_FRAGMENT}
  {
    agencyActiveJobs {
      ...MoblyzeJobFragment

      recruiterJobReport {
        interestedCount
        unviewedInterestedCount
        approvedByClientCount
        approvedByClientUnviewedCount
      }
    }
  }
`;

export const ARCHIVED_JOBS_QUERY = gql`
  ${MOBLYZE_JOB_FRAGMENT}
  {
    agencyArchivedJobs {
      ...MoblyzeJobFragment
    }
  }
`;

export const AGENCY_JOB_DETAILS_QUERY = gql`
  ${MOBLYZE_JOB_FRAGMENT}
  ${AGENCY_CANDIDATE_TRIAGE_REPORT_FRAGMENT}
  query MoblyzeJob($id: ID!) {
    moblyzeJob(id: $id) {
      ...MoblyzeJobFragment
    }

    agencyCandidateTriageInterestedCandidates(moblyzeJobId: $id) {
      ...AgencyCandidateTriageReportFragment
    }
    agencyCandidateTriageShortlistedCandidates(moblyzeJobId: $id) {
      ...AgencyCandidateTriageReportFragment
    }
    agencyCandidateTriageApprovedCandidates(moblyzeJobId: $id) {
      ...AgencyCandidateTriageReportFragment
    }
    agencyCandidateTriageHiredCandidates(moblyzeJobId: $id) {
      ...AgencyCandidateTriageReportFragment
    }
  }
`;

export const AGENCY_PROSPECTIVE_CANDIDATES_FOR_JOB_QUERY = gql`
  ${CANDIDATE_USER_FRAGMENT}
  query AgencyProspectiveCandidatesForJob($moblyzeJobId: ID!) {
    agencyProspectiveCandidatesForJob(moblyzeJobId: $moblyzeJobId) {
      ...CandidateUserFragment
    }
  }
`;

export const AGENCY_CLOSE_JOB_MUTATION = gql`
  mutation CloseJob(
    $moblyzeJobId: ID!
    $reason: MoblyzeClosedReason!
    $closedNote: String!
  ) {
    closeJob(
      moblyzeJobId: $moblyzeJobId
      reason: $reason
      closedNote: $closedNote
    ) {
      id
    }
  }
`;

export const AGENCY_REACTIVATE_JOB_MUTATION = gql`
  mutation ReactivateJob($moblyzeJobId: ID!, $reactivationNote: String!) {
    reactivateJob(
      moblyzeJobId: $moblyzeJobId
      reactivationNote: $reactivationNote
    ) {
      id
    }
  }
`;

export const AGENCY_CANDIDATE_TRIAGE_ADD_TO_SHORTLIST_MUTATION = gql`
  mutation CandidateTriageAddToShortlist(
    $moblyzeJobId: ID!
    $candidateUserId: ID!
  ) {
    candidateTriageAddToShortlist(
      moblyzeJobId: $moblyzeJobId
      candidateUserId: $candidateUserId
    )
  }
`;

export const AGENCY_CANDIDATE_TRIAGE_REMOVE_FROM_SHORTLIST_MUTATION = gql`
  mutation CandidateTriageRemoveFromShortlist(
    $moblyzeJobId: ID!
    $candidateUserId: ID!
  ) {
    candidateTriageRemoveFromShortlist(
      moblyzeJobId: $moblyzeJobId
      candidateUserId: $candidateUserId
    )
  }
`;

export const AGENCY_CANDIDATE_TRIAGE_ADD_TO_PASS_LIST_MUTATION = gql`
  mutation CandidateTriageAddToPassList(
    $moblyzeJobId: ID!
    $candidateUserId: ID!
  ) {
    candidateTriageAddToPassList(
      moblyzeJobId: $moblyzeJobId
      candidateUserId: $candidateUserId
    )
  }
`;

export const AGENCY_CANDIDATE_TRIAGE_MARK_AS_HIRED_MUTATION = gql`
  mutation CandidateTriageMarkAsHired(
    $moblyzeJobId: ID!
    $candidateUserId: ID!
  ) {
    candidateTriageMarkAsHired(
      moblyzeJobId: $moblyzeJobId
      candidateUserId: $candidateUserId
    )
  }
`;

export const CLIENT_NOTES_FOR_CANDIDATE_TRIAGE_QUERY = gql`
  query ClientNotesForCandidateTriage(
    $moblyzeJobId: ID!
    $candidateUserId: ID!
  ) {
    clientNotesForCandidateTriage(
      moblyzeJobId: $moblyzeJobId
      candidateUserId: $candidateUserId
    )
  }
`;

// Client queries and mutations

export const CLIENT_JOB_DETAILS_QUERY = gql`
  ${MOBLYZE_JOB_FRAGMENT}
  ${CANDIDATE_TRIAGE_FRAGMENT}
  query JobDetails($id: ID!) {
    moblyzeJob(id: $id) {
      ...MoblyzeJobFragment
    }

    clientTriageListForJob(moblyzeJobId: $id) {
      ...CandidateTriageFragment
    }
  }
`;

export const CLIENT_ACTIVE_JOBS_QUERY = gql`
  ${MOBLYZE_JOB_FRAGMENT}
  {
    clientActiveJobs {
      ...MoblyzeJobFragment
    }
  }
`;

export const CLIENT_PASS_ON_CANDIDATE_FOR_JOB_MUTATION = gql`
  mutation ClientPassOnCandidateForJob(
    $moblyzeJobId: ID!
    $candidateUserId: ID!
  ) {
    clientPassOnCandidateForJob(
      moblyzeJobId: $moblyzeJobId
      candidateUserId: $candidateUserId
    )
  }
`;

export const CLIENT_APPROVE_CANDIDATE_FOR_JOB_MUTATION = gql`
  mutation ClientApproveCandidateForJob(
    $moblyzeJobId: ID!
    $candidateUserId: ID!
    $note: String
  ) {
    clientApproveCandidateForJob(
      moblyzeJobId: $moblyzeJobId
      candidateUserId: $candidateUserId
      note: $note
    )
  }
`;

export const CANDIDATE_SKILLS_FRAGMENT = gql`
  fragment CandidateSkillsFragment on CandidateRolePreferenceResponse {
    candidateRoles {
      id
      name
    }
    skills {
      id
      name
    }
  }
`;

export const CLIENT_CANDIDATE_SKILLS_QUERY = gql`
  ${CANDIDATE_SKILLS_FRAGMENT}
  query ClientCandidateSkills($candidateProfileId: ID!) {
    getCandidateSkillsByProfileId(candidateProfileId: $candidateProfileId) {
      ...CandidateSkillsFragment
    }
  }
`;
