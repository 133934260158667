import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  Input,
  useToast,
  Checkbox,
  FormLabel,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";

import { UPDATE_CANDIDATE_ROLE_PREF_MUTATION } from "../graphql/agencies";
import MoblyzeApolloClient from "../services/MoblyzeApolloClient";
import { BasicInput, ImageUpload, MultiSelectInput, SelectInput } from "./HookFormsInputs";
import {
  CLIENT_NOTES_FOR_CANDIDATE_TRIAGE_QUERY,
  CLIENT_CANDIDATE_SKILLS_QUERY,
} from "../graphql/jobs";
import {
  CREATE_JOB_MUTATION,
  JOB_CREATE_PAGE_QUERY,
  UPLOAD_JOB_COVER_IMAGE_MUTATION,
  UPDATE_JOB_MUTATION,
  JOB_EDIT_PAGE_QUERY,
  GET_SKILLS_BY_ROLE_QUERY,
  GET_CERTIFICATIONS_BY_ROLE_QUERY,
  GET_CANDIDATE_ROLES_QUERY
} from "../graphql/jobs_admin";
import { sortBy } from "lodash";

export default function UpdateCandidateRolePrefForm({ candidateUser, refetch }) {
  const toast = useToast();
  const [allRoleOptions, setAllRoleOptions] = React.useState([]);
  const [roleSkills, setRoleSkills] = React.useState({});
  const { data: candidateRolesAndSkills } = useQuery(
    CLIENT_CANDIDATE_SKILLS_QUERY,
    {
      variables: {
        candidateProfileId: candidateUser?.candidateProfile?.id,
      },
      skip: !candidateUser?.candidateProfile?.id,
    }
  );
  const defaultRoles = candidateUser?.candidateProfile?.roles?.map(role => ({
    value: role.id,
    label: role.name,
  })) || [];

  const { data: rolesAndSkills } = useQuery(
    GET_CANDIDATE_ROLES_QUERY
  );

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors, isValid, isDirty, isSubmitting, }
  } = useForm({
    defaultValues: {
      roles: defaultRoles,
      skills: [],
    },
    mode: 'onChange', // This enables real-time validation
  });
  const [skillOptions, setSkillOptions] = React.useState([]);

  const selectedRoles = useWatch({
    control,
    name: "roles",
    defaultValue: defaultRoles,
  });

  useEffect(() => {
    const selectedRoleIds = selectedRoles?.map(role => role.value) || [];

    if (selectedRoleIds.length > 0) {
      const allowedSkills = [];
      for (let roleId of selectedRoleIds) {
        if (roleSkills[roleId]) {
          allowedSkills.push(...roleSkills[roleId]);
        }
      }
      setSkillOptions(allowedSkills);
      const allowedSkillIds = allowedSkills.map(skill => skill.value);
      setValue("skills", getValues("skills")?.filter(skill => allowedSkillIds.includes(skill.value)) || []);
    } else {
      setSkillOptions([]);
      setValue("skills", []);
    }
  }, [selectedRoles]);

  useEffect(() => {
    if (candidateRolesAndSkills?.getCandidateSkillsByProfileId && candidateRolesAndSkills.getCandidateSkillsByProfileId.length > 0) {
      const defaultSkills = [];
      for (let role of candidateRolesAndSkills.getCandidateSkillsByProfileId) {
        defaultSkills.push(...role.skills.map(skill => ({
          value: skill.id,
          label: skill.name,
        })));
      }
      setValue("skills", defaultSkills);
    } else {
      setValue("skills", []);
    }
  }, [candidateRolesAndSkills]);

  useEffect(() => {
    if (rolesAndSkills?.candidateRoles && rolesAndSkills?.candidateRoles.length > 0) {
      let roles = [];
      let skills = {};
      for (let role of rolesAndSkills.candidateRoles) {
        roles.push({
          value: role.id,
          label: role.name,
        });
        skills[role.id] = role.requiredSkills.map(skill => ({
          value: skill.id,
          label: skill.name,
        }));
      }
      setAllRoleOptions(roles);
      setRoleSkills(skills);
      if (skillOptions.length === 0 && defaultRoles?.length) {
        const allowedSkills = [];
        for (let roleId of defaultRoles.map(role => role.value)) {
          if (skills[roleId]) {
            allowedSkills.push(...skills[roleId]);
          }
        }
        setSkillOptions(allowedSkills);
      }
    }
  }, [rolesAndSkills]);


  const onFormSubmit = async () => {
    try {
      const roleIds = getValues("roles").map(role => role.value);
      const skillIds = getValues("skills").map(skill => skill.value);
      const skillInput = [];
      const inputtedSkills = [];
      for (let roleId of roleIds) {
        const input = {roleId: roleId, skillIds: []};
        if (roleSkills[roleId]) {
          for (let skillId of skillIds) {
            if (roleSkills[roleId].map(skill => skill.value).includes(skillId) && !inputtedSkills.includes(skillId)) {
              input.skillIds.push(skillId);
              inputtedSkills.push(skillId);
            }
          }
        }
        if (input.skillIds.length > 0) {
          skillInput.push(input);
        }
      }
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPDATE_CANDIDATE_ROLE_PREF_MUTATION,
        variables: {
          id: candidateUser.id,
          roleIds: roleIds,
          skillIds: skillInput,
        },
      });

      if (refetch) {
        refetch();
      }

      toast({
        title: "The roles and skills have been updated",
        status: "success",
      });
      setTimeout(() => {
        window.location.reload();
      }
      , 500
      );
    } catch (e) {
      toast({
        title: e?.message || JSON.stringify(e),
        status: "error",
      });
    }
  };

  return (
    <Box borderRadius="5px" background="gray.800" p="44px 20px" width="100%">
      <Text
        color={"white"}
        fontSize={"28px"}
        fontWeight={600}
        lineHeight={"40px"}
      >
        Edit Candidate Roles and Skills
      </Text>
      <Stack spacing={4} mt="8" width="100%">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <MultiSelectInput
            name="roles"
            label="Role"
            required
            options={allRoleOptions}
            register={register}
            control={control}
            errors={errors}
            ml={'0'}
            mb={'32px'}
          />

          <MultiSelectInput
            name="skills"
            label="Skills"
            options={skillOptions}
            register={register}
            control={control}
            errors={errors}
            ml={'0'}
            mb={'32px'}
          />

          <Button
            width="100%"
            type="submit"
          >
            Update Candidate Roles and Skills
          </Button>
        </form>
      </Stack>
    </Box>
  )
}
