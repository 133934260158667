import React from "react";
import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  Input,
  useToast,
  Checkbox,
  FormLabel,
} from "@chakra-ui/react";

import {
  INVITE_CANDIDATE_MUTATION,
  UPLOAD_CANDIDATE_IMPORT_RESUME_MUTATION,
} from "../graphql/agencies";
import MoblyzeApolloClient from "../services/MoblyzeApolloClient";
import { ResumeFilePicker } from "./Filepicker";

export default function UpdateCandidateProfileForm({currentUser}) {
  const toast = useToast();

  const [bullhornId, setBullhornId] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [primaryPhone, setPrimaryPhone] = React.useState("");

  const [resumeFile, setResumeFile] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const uploadResume = async (candidateProfileImportId) => {
    if (!resumeFile) {
      return;
    }

    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPLOAD_CANDIDATE_IMPORT_RESUME_MUTATION,
        variables: { candidateProfileImportId, resumeFile },
      });
      console.log("Resume upload response", r);
    } catch (e) {
      throw e;
    }
  };

  const onSaveButtonPress = async () => {
    setIsLoading(true);

    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: INVITE_CANDIDATE_MUTATION,
        variables: {
          bullhornId,
          firstName,
          lastName,
          email,
          phone: primaryPhone,
        },
      });
      console.log("Candidate invited response", r);

      const candidateProfileImportId = r?.data?.inviteCandidate?.id;
      await uploadResume(candidateProfileImportId);

      toast({
        title: "Candidate invited and notified!",
        status: "success",
      });
    } catch (e) {
      console.log("Update user error", e);
      toast({
        title: e?.message || JSON.stringify(e),
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box borderRadius="5px" background="gray.800" p="44px 20px" width="100%">
      <Text
        color={"white"}
        fontSize={"28px"}
        fontWeight={600}
        lineHeight={"40px"}
      >
        Invite Candidate
      </Text>
      <Stack spacing={4} mt="8" width="100%">
        {currentUser?.agency?.type !== 'corporate' && (
          <>
            <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
              Bullhorn ID
            </FormLabel>
            <Input
              mt="0px"
              placeholder="Bullhorn ID"
              label="Bullhorn ID"
              value={bullhornId}
              onChange={(e) => setBullhornId(e.target.value)}
            />
          </>
        )}

        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          First Name
        </FormLabel>
        <Input
          mt="0px"
          placeholder="First name"
          label="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          Last Name
        </FormLabel>
        <Input
          mt="0px"
          placeholder="Last name"
          label="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          Email
        </FormLabel>
        <Input
          mt="0px"
          placeholder="Email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          Phone (e.g. +14152231900)
        </FormLabel>
        <Input
          mt="0px"
          placeholder="Phone"
          label="Phone"
          value={primaryPhone}
          onChange={(e) => setPrimaryPhone(e.target.value)}
        />

        <ResumeFilePicker name="resumeFile" setResumeValue={setResumeFile} />

        <Flex align="center">
          <Button
            width="100%"
            onClick={onSaveButtonPress}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Send Candidate Invite
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
