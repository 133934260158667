import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Icon,
  Tag,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  HStack,
  Link,
} from "@chakra-ui/react";

import { FiDownload, FiExternalLink, FiLinkedin, FiMail, FiPhone } from "react-icons/fi";
import {
  AgencyPassAddActions,
  AgencyRemoveShortlistAction,
  AgencyMarkHiredAction,
  AgencyAddToShortlistAction,
} from "./AgencyShortlistActions";
import moment from "moment";
import { ClientPassAddActions } from "./ClientShortlistActions";
import {
  CLIENT_NOTES_FOR_CANDIDATE_TRIAGE_QUERY,
  CLIENT_CANDIDATE_SKILLS_QUERY,
} from "../../graphql/jobs";
import { GET_SKILLS_QUERY } from "../../graphql/jobs_admin";
import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";
import { RECRUITER_VIEWED_CANDIDATE_FOR_JOB_MUTATION } from "../../graphql/agencies";
import { LoadingIndicator } from "../LoadingIndicator";
import colors from "../../helpers/colors";
import WithQuery from "../WithQuery";
import { CURRENT_USER_QUERY } from "../../graphql/users";
import { useQuery } from "@apollo/client";

function ClientNotesInner({ data, loading, error }) {
  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return null;
  }

  const clientNotes = data.clientNotesForCandidateTriage;

  if (!clientNotes || clientNotes.length === 0) {
    return null;
  }

  return (
    <Box>
      <Text fontSize="xl" fontWeight="bold" mb="3">
        Notes from Client:
      </Text>
      <Text fontSize="xl" fontWeight="bold">
        {clientNotes}
      </Text>
    </Box>
  );
}

function ClientNotes({ moblyzeJob, candidate }) {
  if (!candidate || !moblyzeJob) {
    return null;
  }

  return WithQuery(
    ClientNotesInner,
    CLIENT_NOTES_FOR_CANDIDATE_TRIAGE_QUERY,
    { moblyzeJobId: moblyzeJob.id, candidateUserId: candidate.id },
    {
      moblyzeJob,
      candidate,
    }
  )();
}

function calculateSkillMatch(jobSkills, candidateSkills) {
  const jobSkillNames = jobSkills.map((skill) => skill.name);
  const candidateSkillNames = candidateSkills.map((skill) => skill.name);

  const matchingSkills = jobSkillNames.filter((skill) =>
    candidateSkillNames.includes(skill)
  ).length;

  const totalJobSkills = jobSkills.length;
  const matchPercentage =
    totalJobSkills > 0 ? (matchingSkills / totalJobSkills) * 100 : 0;

  const roundedMatchPercentage = Math.round(matchPercentage);

  return {
    matchPercentage: roundedMatchPercentage,
    color: getMatchColor(matchPercentage),
  };
}

function getMatchColor(percentage) {
  if (percentage > 50.5) {
    return "green";
  } else if (percentage >= 25.5 && percentage <= 50.4) {
    return "orange";
  } else {
    return "red";
  }
}

function CandidateDetails({
  candidateReport,
  candidateTriage,
  moblyzeJob,
  isClient,
  showAgencyPassAddActions,
  showAgencyRemoveShortlistAction,
  showAgencyMarkHiredAction,
  showAgencyAddToShortlistAction,
  showClientPassAddActions,
  showClientNotes,
  refetch,
  markViewedByRecruiterType,
}) {
  const employmentPreference = null;
  const dateAvailable = null;
  const currentJobTitle = null;
  const visaType = null;
  const currentJobStatus = null;

  // if recruiter views, call mutation on mount to mark as viewed
  useEffect(() => {
    if (
      !isClient &&
      candidateReport?.viewedByRecruiter === false &&
      markViewedByRecruiterType !== ""
    ) {
      MoblyzeApolloClient.mutate({
        mutation: RECRUITER_VIEWED_CANDIDATE_FOR_JOB_MUTATION,
        variables: {
          candidateUserId: candidateReport.candidateUser.id,
          moblyzeJobId: moblyzeJob.id,
          recruiterViewType: markViewedByRecruiterType,
        },
      }).then(() => {
        if (refetch) {
          refetch();
        }
      });
    }
  }, [candidateReport, markViewedByRecruiterType, isClient, moblyzeJob, refetch]);

  const renderActions = ({ candidate, candidateTriage, moblyzeJob }) => {
    if (isClient) {
      if (showClientPassAddActions) {
        return (
          <ClientPassAddActions
            candidateTriageStatus={candidateTriage?.status}
            candidate={candidate}
            moblyzeJob={moblyzeJob}
            refetch={refetch}
          />
        );
      }
      return null;
    }

    if (showAgencyPassAddActions) {
      return (
        <AgencyPassAddActions
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    if (showAgencyRemoveShortlistAction) {
      return (
        <AgencyRemoveShortlistAction
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    if (showAgencyMarkHiredAction) {
      return (
        <AgencyMarkHiredAction
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    if (showAgencyAddToShortlistAction) {
      return (
        <AgencyAddToShortlistAction
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    return null;
  };

  const candidate =
    candidateReport?.candidateUser || candidateTriage?.candidateUser;

  const {
    isOpen: isResumeOpen,
    onOpen: onResumeOpen,
    onClose: onResumeClose,
  } = useDisclosure();

  const [openModalIndex, setOpenModalIndex] = useState(null);

  const openModal = (index) => {
    setOpenModalIndex(index);
  };

  const closeModal = () => {
    setOpenModalIndex(null);
  };

  const { data: user } = useQuery(CURRENT_USER_QUERY);
  const userRole = user?.currentUser?.role;

  const { data: candidateSkillsData } = useQuery(
    CLIENT_CANDIDATE_SKILLS_QUERY,
    {
      variables: {
        candidateProfileId: candidate?.candidateProfile?.id,
      },
      skip: !candidate?.candidateProfile?.id,
    }
  );

  const { data: skillsData } = useQuery(GET_SKILLS_QUERY, {
    variables: {
      moblyzeJobId: moblyzeJob?.id,
    },
    skip: !moblyzeJob?.id,
  });

  // Assuming skillsData contains the job skills
  const jobRoles = moblyzeJob?.roles || []; // Assuming moblyzeJob contains roles
  const jobSkills = skillsData?.getSkills || [];
  const candidateSkills =
    candidateSkillsData?.getCandidateSkillsByProfileId.flatMap(
      (role) => role.skills
    ) || [];

  // Calculate match percentage
  const { matchPercentage, color } = calculateSkillMatch(
    jobSkills,
    candidateSkills
  );

  const candidateRoles = candidate?.candidateProfile?.roles || [];

  return (
    <>
      <Flex
        direction="column"
        flex={1}
        gap="24px"
        style={{
          border: "1px solid #4A5568",
          borderRadius: "6px",
          overflow: "hidden",
        }}
        padding={"24px"}
      >
        {showClientNotes && (
          <ClientNotes candidate={candidate} moblyzeJob={moblyzeJob} />
        )}

        <Box>
          <Flex flexDirection={"row"} justifyContent={"space-between"} mb="4">
            <Flex alignItems="center">
              <Flex flexDirection={"column"} alignItems="center">
                <Box
                  style={{
                    backgroundImage: `url(${candidate?.profileImageUrl ||
                      "https://ui-avatars.com/api/?name=" +
                      candidate?.fullName.replace(/ /g, "+") +
                      "&background=random&rounded=true"
                      })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    height: "100px",
                    width: "100px",
                    borderRadius: "50px",
                  }}
                  alt="User"
                />
                {(candidate?.activelyLooking ||
                  candidate?.candidateProfile?.activelyLooking) && (
                    <Tag
                      backgroundColor={"#68D391"}
                      style={{ textTransform: "uppercase" }}
                      color={colors.black}
                      fontSize={"12px"}
                      fontWeight={600}
                      variant="solid"
                      colorScheme="teal"
                      mt="8px"
                    >
                      Open for Work
                    </Tag>
                  )}
              </Flex>
              <Box ml={"24px"}>
                <Text fontSize="3xl" fontWeight="semibold">
                  {candidate?.fullName}
                </Text>
                <HStack mb={1}>
                  <Icon as={FiMail} />
                  <Link color={"white"} href={"mailto:" + candidate?.email}>{candidate?.email}</Link>
                </HStack>
                <HStack>
                  <Icon as={FiPhone} />
                  <Link color={"white"} href={"tel:" + candidate?.primaryPhone}>{candidate?.primaryPhone}</Link>
                </HStack>
                {candidate?.candidateProfile?.linkedInURL && (
                  <HStack>
                    <Icon as={FiLinkedin} />
                    <Link color={"white"} target="_blank" href={candidate?.candidateProfile?.linkedInURL}>{candidate?.candidateProfile?.linkedInURL}</Link>
                  </HStack>
                )}
              </Box>
            </Flex>
            <Box>
              {renderActions({ candidate, candidateTriage, moblyzeJob })}
            </Box>
          </Flex>


          {candidate?.candidateProfile?.agencySlugId && (
            <Flex alignItems="center" gap="4px" mt="16px">
              <img
                src={candidate?.candidateProfile?.agencyLogoUrl}
                height={20}
                width={20}
                alt={candidate?.candidateProfile?.agencySlugId}
                style={{ paddingRight: "4px" }}
              />
              <Text
                fontSize="md"
                fontWeight="normal"
                lineHeight="6"
                color={colors.greyLight}
              >
                {candidate?.candidateProfile?.agencySlugId}
              </Text>
            </Flex>
          )}

          {!!visaType && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="xl" fontWeight="bold">
                Visa Type
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {visaType}
              </Text>
            </Flex>
          )}

          {currentJobStatus && (
            <Flex paddingTop="24px" gap="24px">
              <Flex direction="column" gap="12px">
                <Text fontSize="xl" fontWeight="bold">
                  Current Job Status
                </Text>
                <Text fontSize="md" fontWeight="normal" lineHeight="6">
                  {currentJobStatus}
                </Text>
              </Flex>
            </Flex>
          )}

          {!!currentJobTitle && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Current Job Title
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {currentJobTitle}
              </Text>
            </Flex>
          )}

          {dateAvailable && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Date Available
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {moment(dateAvailable).format("DD/MM/YY")}
              </Text>
            </Flex>
          )}
          {employmentPreference && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Employee Preference
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {employmentPreference}
              </Text>
            </Flex>
          )}

          <Flex direction="column" flex={1} gap="12px">
            <Text fontSize="md" fontWeight="semibold" lineHeight="6">
              Referral Code:&nbsp;
              {candidate?.referralCode}&nbsp;
              ({candidate?.referrals?.length} referrals)
            </Text>
          </Flex>

          <Flex paddingTop="24px" gap="24px">
            <Flex direction="column" gap="12px">
              <Text fontSize="xl" fontWeight="bold">
                Roles
              </Text>
              <Flex wrap="wrap" gap="8px">
                {candidateRoles.map((role) => {
                  const isMatchingRole = jobRoles.some(
                    (jobRole) => jobRole.name === role.name
                  );
                  return (
                    <Tag
                      key={role.id}
                      variant="solid"
                      backgroundColor={isMatchingRole ? "#48BB78" : "gray.600"}
                      color="#ffffff"
                      fontSize="14px"
                      fontWeight="500"
                      padding="8px 10px"
                    >
                      {role.name}
                    </Tag>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>

          <Flex paddingTop="24px" gap="24px">
            <Flex direction="column" gap="12px">
              <Text fontSize="xl" fontWeight="bold">
                Matching Skills:{" "}
                <span style={{ color }}>{matchPercentage}%</span>
              </Text>
              <Flex wrap="wrap" gap="8px">
                {candidateSkillsData?.getCandidateSkillsByProfileId?.map(
                  (rolePreference) =>
                    rolePreference.skills.map((skill) => {
                      const isMatchingSkill = jobSkills.some(
                        (jobSkill) => jobSkill.name === skill.name
                      );
                      return (
                        <Tag
                          key={skill.id}
                          variant="solid"
                          backgroundColor={
                            isMatchingSkill ? "#48BB78" : "gray.600"
                          }
                          color="#ffffff"
                          fontSize="14px"
                          fontWeight="500"
                          padding="8px 10px"
                        >
                          {skill.name}
                        </Tag>
                      );
                    })
                )}
              </Flex>
            </Flex>
          </Flex>
        </Box>


        {/* Resume */}
        {candidate?.candidateProfile?.resumeUrl && (
          <>
            <Box mt="24px">
              <Text fontSize="xl" fontWeight="bold" mb="4">
                CV/Resume
              </Text>

              {candidate?.candidateProfile?.resumeContent ? (
                <Box
                  borderRadius="5px"
                  flex={1}
                  background="gray.800"
                  p="20px"
                  width="100%"
                >
                  <Flex
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb="4"
                  >
                    <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                      AI summary
                    </Text>
                  </Flex>
                  <Flex
                    direction={"column"}
                    mb="4"
                  >
                    <div dangerouslySetInnerHTML={{ __html: candidate?.candidateProfile?.resumeContent }} className="ai-cv-summary"></div>
                  </Flex>
                </Box>
              ) : (
                <>
                  <Flex
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb="4"
                  >
                    <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                    </Text>

                    <a
                      href={candidate?.candidateProfile?.resumeUrl}
                      download
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="20px"
                        p="4px 8px"
                        fontSize="sm"
                        fontWeight="semibold"
                        lineHeight="6"
                        color="white"
                        cursor="pointer"
                      >
                        <Icon color="white" fontSize="16px" as={FiDownload} mr="2" />
                        Download
                      </Flex>
                    </a>
                  </Flex>

                  <iframe
                    title="resume"
                    width="100%"
                    height="800px"
                    src={candidate?.candidateProfile?.resumePreviewUrl}
                  ></iframe>
                </>
              )}
            </Box>

            {candidate?.candidateProfile?.resumeContent && (
              <Box>
                <Button onClick={onResumeOpen} variant="outline">
                  Open CV
                </Button>
                <Modal
                  isOpen={isResumeOpen}
                  onClose={onResumeClose}
                  isCentered
                  size={"4xl"}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader color={colors.black}>
                      <Flex
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Text>{candidate?.fullName}</Text>
                        <a
                          href={candidate?.candidateProfile?.resumeUrl}
                          download
                          style={{
                            textDecoration: "none",
                            marginRight: "40px",
                            fontSize: "14px",
                            alignItems: "center",
                            display: "flex",
                            position: "relative",
                            top: "-4px",
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon
                            color={colors.black}
                            fontSize="16px"
                            as={FiDownload}
                            mr="2"
                          />
                          Download
                        </a>
                      </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <iframe
                        title="resume"
                        width="100%"
                        height="700px"
                        src={candidate?.candidateProfile?.resumePreviewUrl}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button colorScheme="blue" mr={3} onClick={onResumeClose}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Box>
            )}
          </>
        )}

        {/* Certifications */}
        <Box mt="24px">
          {candidate?.candidateProfile?.certification?.length > 0 && (
            <Text fontSize="xl" fontWeight="bold" mb="2">
              Certifications
            </Text>
          )}
          {candidate?.candidateProfile?.certification?.map((cert, index) => (
            <Box key={index} mt="12px">
              <Text fontSize="sm" fontWeight="normal" mb="2">
                {cert.name}
              </Text>
              <Button
                onClick={() => openModal(index)}
                variant="outline"
                colorScheme=""
              >
                Open {cert.name}
              </Button>
              <Modal
                isOpen={openModalIndex === index}
                onClose={closeModal}
                isCentered
                size={"4xl"}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader color={colors.black}>
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text color={colors.black}>{cert?.name}</Text>
                      <a
                        href={cert?.url}
                        download
                        style={{
                          textDecoration: "none",
                          marginRight: "40px",
                          fontSize: "14px",
                          alignItems: "center",
                          display: "flex",
                          position: "relative",
                          top: "-4px",
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon
                          color={colors.black}
                          fontSize="16px"
                          as={FiDownload}
                          mr="2"
                        />
                        Download
                      </a>
                    </Flex>
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <iframe
                      title="certification"
                      width="100%"
                      height="700px"
                      src={cert?.url}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={closeModal}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
          ))}
        </Box>

      </Flex>
    </>
  );
}

export default CandidateDetails;