import React from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";

import WithAppLayout from "../../../components/WithAppLayout";
import InviteCandidateForm from "../../../components/InviteCandidateForm";

function CandidateInvitePage({ currentUser }) {
  return (
    <Flex h="100%" direction="column" flex="1">
      <Flex direction="row" align="flex-start" justify="flex-start">
        <Flex
          direction="column"
          flex="1"
          align="flex-start"
          justify="flex-start"
          mr={8}
        >
          <InviteCandidateForm currentUser={currentUser}/>
        </Flex>
        <Flex
          direction="column"
          flex="0.5"
          align="flex-start"
          justify="flex-start"
        ></Flex>
      </Flex>
    </Flex>
  );
}

export default WithAppLayout(CandidateInvitePage);
